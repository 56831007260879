<template>
    <div class="a-s-t" 
    v-loading="loading"
    element-loading-text="正在计算中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                    <el-autocomplete
                    class="inline-input"
                    v-model="form.keys"
                    :fetch-suggestions="getAuxiliary"
                    placeholder="选择辅助统计"
                    @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="addTaskRule">
                    <el-button class="margin" @click="searchSta" icon="el-icon-search" size="small">搜索</el-button>
                    <el-dropdown  class="margin" @command="handleCommand"  v-if="calList.length>=1">
                        <el-button type="success" size="small">
                            导出<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1" @click="exportData">导出纯文本数据到excel</el-dropdown-item>
                            <el-dropdown-item command="2" @click="exportImgData">导出数据（包含图片）到excel</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    <!-- <el-button type="success" v-if="calList.length>=1" class="margin" @click="exportData" icon="el-icon-download" size="small">导出</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <!-- table -->
        <div class="table" v-if="calList.length>=1">
                <el-table
                :data="calList"
                border
                height="500"
                style="width:100%">
                    <el-table-column
                    type="index"
                    label="索引"
                    :index="indexMethod">
                    </el-table-column>
                    <el-table-column
                        v-for="(item,index) in calList[0]"
                        :key="index"
                        :label="item.title">
                        <template slot-scope="scope">
                            <div v-if="!scope.row[index].type">
                                {{scope.row[index].value}}
                            </div>
                            <div v-else-if="scope.row[index].type=='image'">
                                <!-- {{scope.row[index].value}} -->
                                <imageGroup :images="scope.row[index].value" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    fixed="right">
                    <template slot-scope="scope">
                    <el-button @click="rowClick(scope.row)" type="text" size="small">查看报告</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 弹出报告 -->
            <el-dialog v-el-drag-dialog  append-to-body :title="dialogTitle"
            :mask="false" :modal="false" :visible.sync="showReport" width="40%">
                <ReportShow :id="tid" :user_token="token"/>
            </el-dialog>
            <!-- 弹出 -->
            <el-dialog
            title="提示"
            :modal="false"
            :visible.sync="showMod"
            width="40%">
            <span>{{calMsg}}</span>
            <div>
                 <el-table
                :data="errorList"
                style="width: 100%">
                    <el-table-column
                        prop="tid"
                        label="工单id"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="client_name"
                        label="客户名称">
                    </el-table-column>
                    <el-table-column
                        prop="staff_name"
                        label="员工">
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        label="时间">
                    </el-table-column>
                    <el-table-column
                        label="错误日志">
                        <template slot-scope="scope">
                            {{scope.row.error}}
                        </template>
                    </el-table-column>
                 </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="warning" @click="showMod = false">确 定</el-button>
            </span>
            </el-dialog>
    </div>
</template>
<script>
import {exportExcel} from '@/utils/excel.js'
import {formatTime,checkRules} from '@/utils/util.js'
import imageGroup from '@/components/test/imageGroup'
import ReportShow from '@/views/Report/show'
export default {
    name:"AuxiliaryStatistics",
    components:{
        imageGroup,
        ReportShow
    },
    props:{
        start:{
            type:String
        },
        timeArr:{
            type:Array
        }
    },
    data(){
        return{
            dialogTitle:"",
            tid:"",
            token:"",
            showReport:false,//显示报告
            calMsg:'',//显示弹出错误信息
            errorList:[],//错误行
            showMod:false,
            loading:false,
            addTaskRule:checkRules(10),//添加编辑任务权限
            form:{
                keys:''
            },
            name:"",
            optionList:[],
            ASList:[],//辅助统计表单
            calList:[],//计算的辅助统计表单
            deviceChecklist:[],//设备检查记录
        }
    },
    created(){
        this.getAuxiliary()
    },
    methods:{
        //选择导出
        handleCommand(e){
            //console.log(e)
            switch(e){
                //导出纯文本execl
                case "1":
                    this.exportData()
                    break;
                case "2":
                    this.exportImgData()
                    break;
            }
        },
        //点击
        rowClick(e){
            //console.log(e,'kkkk')
            this.tid = e[0].value
            this.dialogTitle =e[0].value+'_'+ e[1].value
            let user = JSON.parse(sessionStorage.getItem('user'))
            this.token = user.token
            console.log(this.token,'token-')
            this.showReport = true 
            // let user = JSON.parse(sessionStorage.getItem('user'))
            // if(e[0]&&!isNaN(e[0].value)){
            //     //tid
            //     let url = this.$router.resolve({path:'/Report/show/'+e[0].value,query:{token:user.token}})
            //     //console.log(url)
            //     window.open(url.href, '_blank');
            // }
        },
        //导出包含图片的数据
        exportImgData(){
            console.log(this.calList)
            let params = {
                method:"downloadAuxData",
                title:formatTime(this.timeArr[0]).split(' ')[0]+'至'+formatTime(this.timeArr[1]).split(' ')[0],
                calList:JSON.stringify(this.calList),
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                    this.loading = false
                    console.log(res)
                    if(res.data){
                        let fileUrl = this.API.api+res.data
                        //console.log(fileUrl)
                        window.open(fileUrl)
                    }
                 }).catch(err=>{
                     console.log(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        },
        //导出数据
        exportData(){
            let columnTitle=['序号']
            let columnData = []
            this.calList[0].forEach(it=>{
                columnTitle.push(it.title)
            })
            console.log(this.timeArr,formatTime(this.timeArr[0]).split(' ')[0])
            this.calList.forEach((it,index)=>{
                let valueList=[]
                valueList.push((index+1))
                it.forEach(item=>{
                    // if(item.type&&item.type=='image'){
                    //     let imgs=item.value,value=''
                    //     if(imgs&&imgs[0]){
                    //         imgs.forEach(it1=>{
                    //             value+=it1+','
                    //         })
                    //     }
                    //     valueList.push(value)
                    // }else{
                        valueList.push(item.value)
                    //}
                    
                })
                columnData.push(valueList)
            })
            exportExcel(columnTitle, columnData, formatTime(this.timeArr[0]).split(' ')[0]+'至'+formatTime(this.timeArr[1]).split(' ')[0]+"辅助统计导出数据");
            
        },
        //重新分类数据
        resetJsonBy(array,key,asKey){
            !asKey?asKey=key:''
            let json={},arr = []
            array.forEach(item=>{
                try {
                    item.deviceData=JSON.parse(item.device_data)
                } catch (error) {
                    console.log(error)
                }
                try {
                    item.dataCheck=JSON.parse(item.data_check)
                } catch (error) {
                     console.log(error)
                }
                if(item.camera){
                    try {
                        item.images = JSON.parse(item.camera)
                    } catch (error) {
                        console.log(error)
                    }
                }
                
                //console.log(item[key])
                if(!json[item[key]]){
                    let m = {
                    data:[]
                    }
                    m[asKey]=item[key]
                    m.data.push(item)
                    arr.push(m)
                    //console.log(m)
                    json[item[key]]=arr
                }else {
                    //console.log(arr)
                    arr.forEach((it,index)=>{
                    if(it[asKey]==item[key]){
                    //console.log(it)
                    arr[index].data.push(item)
                    }
                    })
                }
            })
            return arr
        },
        searchSta(){
            if(!this.name){
                this.$message.error('请选择辅助统计表单！');
                return
            }
            let params = {
                method:"getStatiscsFromTask",
                name:this.name,
                start:formatTime(this.timeArr[0]),
                end:formatTime(this.timeArr[1])
            },
            _this=this
            this.loading=true
            this.$ajax.post(this.API.api,params).then(res=>{
                if(res.data.device&&res.data.device.length>=1){
                    this.deviceChecklist = this.resetJsonBy(res.data.device,'tid')
                }
                //console.log(res.msg)
                if(res.data.task){
                    let list = res.data.task,calList=[],beforeLength=list.length;
                    //console.log(`工单数量：${list.length}条`)
                    list.forEach((item)=>{
                        //console.log(item)
                        let rowItem=[],totalScore=0,isCal=false
                        rowItem.push({
                            title:"工单id",
                            value:item.tid
                        })
                        rowItem.push({
                            title:"客户",
                            value:item.client_name
                        })
                        rowItem.push({
                            title:"员工",
                            value:item.staff_name
                        })
                        rowItem.push({
                            title:"日期",
                            value:item.time?item.time.split(" ")[0]:''
                        })
                        if(item.content){
                            try {
                                item.json = JSON.parse(item.content)
                            } catch (error) {
                                // console.log(item)
                                let message = `工单${item.tid}[${item.client_name}]${item.staff_name}（时间：${item.start_time?item:''}）,出错了！请检查'`
                                this.$message.error(message)
                                console.log(error)
                                item.error = error
                                console.log(item.error)
                                this.errorList.push(item)
                                return
                            }
                            _this.ASList.forEach((it,it_index)=>{
                                let mItem={title:"",value:"",type:''}
                                //console.log(it.type)
                                switch(it.type){
                                    case "device":
                                        mItem={title:it.name,value:"",index:it_index}
                                        for(let i=0;i<_this.deviceChecklist.length;i++){
                                            if(_this.deviceChecklist[i].tid==item.tid){
                                                let  rList= _this.deviceChecklist[i].data
                                                if(it.rules.getDeviceImgsNum){
                                                    //获取设备图片数量
                                                    let num = 0
                                                    rList.forEach(val=>{
                                                        if(val.images){
                                                            num+=val.images.length
                                                        }
                                                    })
                                                    //console.log(mItem.value,'设备虫害数据')
                                                    mItem.value=num
                                                }else if(it.rules.getDeviceImgs){
                                                    //获取设备图片
                                                    mItem.type="image"
                                                    let images=[]
                                                    rList.forEach(val=>{
                                                        if(val.device_name==it.rules.children&&val.images){
                                                                val.images.forEach(it=>{
                                                                    images.push(it.url)
                                                                })
                                                        }
                                                    })
                                                    //console.log(mItem.value,'设备虫害数据')
                                                    mItem.value=images
                                                }else if(it.rules.cal_type=="deviceData"){
                                                    //获取数据或虫害数据
                                                        let num = 0
                                                        //console.log(it.rules.childrenItem,'123-')
                                                    if(it.rules.childrenItem){
                                                        //获取设备某种虫害数据
                                                        rList.forEach(val=>{
                                                            if(val.deviceData&&val.device_name==it.rules.children){
                                                                val.deviceData.forEach(val1=>{
                                                                    if(val1.name==it.rules.childrenItem){
                                                                        num+=parseInt(val1.num)
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        //console.log(mItem.value,'设备虫害数据')
                                                        mItem.value=num
                                                    }else if(it.rules.children){
                                                        //获取设备中某设备的数量
                                                        rList.forEach(val=>{
                                                            //console.log(val.device_name,it.rules.children,it.rules.childrenItem)
                                                            if(val.device_name==it.rules.children){
                                                                val.deviceData.forEach(val1=>{
                                                                    num+=parseInt(val1.num)
                                                                })
                                                            }
                                                        })
                                                        mItem.value=num
                                                    }else{
                                                        //获取总设备数量
                                                        mItem.value=rList.length
                                                    }
                                                }else if(it.rules.cal_type=="deviceCheck"){
                                                    let num=0
                                                    let  rList= _this.deviceChecklist[i].data
                                                    //设备检查数据
                                                    if(it.rules.childrenItem){
                                                        //获取设备某操作方式的数量（比如“更好粘鼠板”）
                                                        rList.forEach(val=>{
                                                            //console.log(val.device_name,it.rules.children,val.deviceCheck)
                                                            if(val.dataCheck&&val.device_name==it.rules.children){
                                                                val.dataCheck.forEach(val1=>{
                                                                    //console.log(val.name,val1.name,val1.checked)
                                                                    if(val1.name==it.rules.childrenItem&&val1.checked==true){
                                                                        num+=1
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        mItem.value=num
                                                    }
                                                    else if(it.rules.children){
                                                        //获取设备中某设备的数量
                                                        rList.forEach(val=>{
                                                            //console.log(val.device_name,it.rules.children,it.rules.childrenItem)
                                                            if(val.device_name==it.rules.children){
                                                                num++
                                                            }
                                                        })
                                                        mItem.value=num
                                                    }
                                                    // else{
                                                    // }
                                                }
                                                break;
                                            }
                                        }
                                        rowItem.push(mItem)
                                        if(it.rules.isCal){
                                            let score = mItem.value
                                            //console.log(mItem,it.rules.condition.max.disabled,it.rules.condition.mini.disabled)
                                            //记分
                                            if(!isNaN(score)){
                                                mItem = {
                                                    title:it.name+"得分",
                                                    value:it.rules.score,
                                                    index:it.it_index
                                                }
                                                if(it.rules.condition.max.disabled){
                                                    //大于多少记分
                                                    if(score>=it.rules.condition.max.data){
                                                        mItem.value = it.rules.condition.max.score*1
                                                    }
                                                }
                                                if(it.rules.condition.mini.disabled){
                                                    //少于多少记分
                                                    if(score<it.rules.condition.mini.data){
                                                        //console.log('小于条件',it.rules.condition.mini.score)
                                                        mItem.value = it.rules.condition.mini.score*1
                                                    }
                                                }
                                                if(mItem.value&&!isNaN(mItem.value)){
                                                    totalScore+=mItem.value*1
                                                }
                                                rowItem.push(mItem)
                                            }
                                        }
                                        //mItem={}
                                        break;
                                    //列表
                                    case "ListAdd":
                                        //console.log(it.rules.cal_type)
                                        if(it.rules.cal_type=="num"){
                                            //计算数量
                                            if(it.index1!=undefined&&it.index2!=undefined){
                                                //在nav中的addlist组件
                                                let kdata=item.json[it.page].list[it.current].setting.list[it.index1].pages[it.index2].data
                                                //console.log(kdata)
                                                let num = 0, arr=[]
                                                if(it.rules.condition.delimiter&&it.index3!=undefined){
                                                    //有分隔符，且有index3
                                                    kdata.forEach(item2=>{
                                                        //console.log(item2,'000-')
                                                        arr = item2[it.index3].data.split(it.rules.condition.delimiter)
                                                        num+=arr.length
                                                    })
                                                    mItem={
                                                        title:it.name,
                                                        value:num,
                                                        index:it.it_index
                                                    }
                                                }else{
                                                    mItem={
                                                        title:it.name,
                                                        value:kdata?kdata.length:0,
                                                        index:it.it_index
                                                    }
                                                    num=kdata?kdata.length:0
                                                }
                                                rowItem.push(mItem)
                                                mItem={}
                                                if(it.rules.isCal==true){
                                                    //计算得分
                                                    mItem.title=it.name+"得分"
                                                    mItem.index=it.it_index
                                                    isCal=true
                                                    mItem.value=it.rules.score*1
                                                    //console.log(mItem,'qqq-')
                                                    if(it.rules.condition.max&&it.rules.condition.max.disabled){
                                                        //最大值
                                                        if(parseInt(num)>parseInt(it.rules.condition.max.data)){
                                                            //大于最大值
                                                            mItem.value=it.rules.condition.max.score*1
                                                            //console.log(item.spend,it.rules.condition.max.data,"大于最大值",mItem.value)
                                                        }
                                                    }else if(it.rules.condition.mini&&it.rules.condition.mini.disabled){
                                                        //最小值
                                                        if(parseInt(num)<parseInt(it.rules.condition.mini.data)){
                                                            //小于最小值
                                                            mItem.value=it.rules.condition.mini.score*1
                                                            //console.log(it.name,parseInt(num),parseInt(it.rules.condition.mini.data))
                                                        }
                                                    }
                                                    totalScore+=mItem.value
                                                    rowItem.push(mItem)
                                                    mItem={}
                                                }
                                            }else{
                                                //addList作为单独组件
                                                 //console.log(it)
                                                 let kdata=item.json[it.page].list[it.current].data
                                                 //console.log(kdata)
                                                 let num = 0, arr=[]
                                                if(it.rules.condition.delimiter&&it.index3!=undefined){
                                                    //有分隔符，且有index3
                                                    kdata.forEach(item2=>{
                                                        //console.log(item2,'000-')
                                                        arr = item2[it.index3].data.split(it.rules.condition.delimiter)
                                                        num+=arr.length
                                                    })
                                                    mItem={
                                                        title:it.name,
                                                        value:num,
                                                        index:it.it_index
                                                    }
                                                }else{
                                                    mItem={
                                                        title:it.name,
                                                        value:kdata?kdata.length:0,
                                                        index:it.it_index
                                                    }
                                                    num=kdata?kdata.length:0
                                                }
                                                rowItem.push(mItem)
                                                mItem={}
                                                if(it.rules.isCal==true){
                                                    //计算得分
                                                    mItem.title=it.name+"得分"
                                                    isCal=true
                                                    mItem.value=it.rules.score*1
                                                    mItem.index=it.it_index
                                                    //console.log(mItem,'qqq-')
                                                    if(it.rules.condition.max&&it.rules.condition.max.disabled){
                                                        //最大值
                                                        if(parseInt(num)>parseInt(it.rules.condition.max.data)){
                                                            //大于最大值
                                                            mItem.value=it.rules.condition.max.score*1
                                                            //console.log(item.spend,it.rules.condition.max.data,"大于最大值",mItem.value)
                                                        }
                                                    }else if(it.rules.condition.mini&&it.rules.condition.mini.disabled){
                                                        //最小值
                                                        if(parseInt(num)<parseInt(it.rules.condition.mini.data)){
                                                            //小于最小值
                                                            mItem.value=it.rules.condition.mini.score*1
                                                            //console.log(it.name,parseInt(num),parseInt(it.rules.condition.mini.data))
                                                        }
                                                    }
                                                    totalScore+=mItem.value
                                                    rowItem.push(mItem)
                                                    mItem={}
                                                }
                                            }
                                        }else if(it.rules.cal_type=="children"){
                                            //子组件数据
                                            //console.log(it.index1,it.index2,it.index3)
                                            //console.log(item.json[it.page].list[it.current].setting.list[it.index1])
                                            if(it.index1!=undefined&&it.index2!=undefined){
                                                //AddList组件位于nav导航内
                                                let kdata=item.json[it.page].list[it.current].setting.list[it.index1].pages[it.index2]
                                                let stringdata='',stringType='',images=[]
                                                let mdata=kdata.data
                                                //console.log(mdata[it.index3])
                                                if(mdata&&mdata.length>=1){
                                                    //有数据
                                                    mdata.forEach((item2,indexKey)=>{
                                                        //console.log(item2)
                                                        if(!item2[it.index3]||!item2[it.index3].menu){
                                                            return
                                                        }
                                                        switch(item2[it.index3].menu){
                                                            //文本子组件
                                                            case "text":
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                            //图片子组件
                                                            case "upload":
                                                                //console.log(item2[it.index3].data)
                                                                stringType="image"
                                                                if(item2[it.index3].data){
                                                                    item2[it.index3].data.forEach(item5=>{
                                                                       images.push(item5.url)
                                                                    })
                                                                }
                                                                break;
                                                            //单选子组件
                                                            case "radio":
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                            //多选子组件
                                                            case "checkBox":
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                        }
                                                    })

                                                }
                                                mItem={
                                                    title:it.name,
                                                    value:stringdata,
                                                    index:it.it_index
                                                }
                                                if(stringType){
                                                    mItem.type = stringType
                                                    mItem.value = images
                                                }
                                                rowItem.push(mItem)
                                                mItem={}
                                            }else{
                                                //AddList单独组件
                                                let mdata=item.json[it.page].list[it.current].data
                                                let stringdata=''
                                                //console.log(mdata)
                                                if(mdata&&mdata.length>=1){
                                                    //有数据
                                                    mdata.forEach((item2,indexKey)=>{
                                                        //console.log(item2)
                                                        if(!item2[it.index3]||!item2[it.index3].menu){
                                                            return
                                                        }
                                                        switch(item2[it.index3].menu){
                                                            //文本子组件
                                                            case "text":
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                            //图片子组件
                                                            case "upload":
                                                                break;
                                                            //单选子组件
                                                            case "radio":
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                            //多选子组件
                                                            case "checkBox":
                                                                //console.log(item2[it.index3].data)
                                                                stringdata+=item2[it.index3].data!='无'&&item2[it.index3].data?'('+(indexKey+1)+') '+item2[it.index3].data:''
                                                                break;
                                                        }
                                                    })

                                                }
                                                mItem={
                                                    title:it.name,
                                                    value:stringdata,
                                                    index:it.it_index
                                                }
                                                rowItem.push(mItem)
                                                mItem={}
                                            }
                                        }
                                        break;
                                    //签到
                                    case "sign":
                                        //console.log(it)
                                        mItem.title=it.name
                                        if(it.rules.cal_type=="usetime"){
                                            //统计类型，时间
                                           // mItem.title=it.name
                                            //item.json[0].list[it.page][it.current]
                                            mItem.value=item.spend
                                            //console.log(mItem,"000-")
                                            rowItem.push(mItem)
                                            mItem={}
                                        }else if(it.rules.cal_type=="end_address"){
                                            //签退
                                           // mItem.title=it.name 
                                           mItem.value=item.json[it.page].list[it.current].data[0]?item.json[it.page].list[it.current].data[0].address:""
                                            rowItem.push(mItem)
                                            mItem={}
                                        }else if(it.rules.cal_type=="start_address"){
                                            mItem.value=item.json[it.page].list[it.current].data[0]?item.json[it.page].list[it.current].data[1].address:""
                                            rowItem.push(mItem)
                                            mItem={}
                                        }
                                        if(it.rules.isCal==true){
                                            //计算得分
                                            mItem.title=it.name+"得分"
                                            mItem.value=it.rules.score*1
                                            isCal=true
                                            //console.log(mItem,'qqq-')
                                            if(it.rules.condition.max&&it.rules.condition.max.disabled){
                                                //最大值
                                                if(parseInt(item.spend)>parseInt(it.rules.condition.max.data)){
                                                    //大于最大值
                                                    mItem.value=it.rules.condition.max.score*1
                                                    //console.log(item.spend,it.rules.condition.max.data,"大于最大值",mItem.value)
                                                }
                                            }else if(it.rules.condition.mini&&it.rules.condition.mini.disabled){
                                                //最小值
                                                if(parseInt(item.spend)<parseInt(it.rules.condition.mini.data)){
                                                    //小于最小值
                                                    mItem.value=it.rules.condition.mini.score*1
                                                    //console.log(item.spend,it.rules.condition.mini.data,"小于最小值",mItem.value)
                                                }
                                            }
                                            totalScore+=mItem.value
                                            //console.log('最大值',item.spend,it.rules.condition.max.data,parseInt(item.spend)<parseInt(it.rules.condition.mini.data),it.rules.condition.mini.data,parseInt(item.spend)>parseInt(it.rules.condition.max.data))
                                            rowItem.push(mItem)
                                            mItem={}
                                        }
                                        //开始时间
                                        if(it.rules.cal_type=="start"){
                                            mItem.title=it.name
                                            mItem.value=item.json[it.page].list[it.current].data[0]?item.json[it.page].list[it.current].data[0].time:""
                                            rowItem.push(mItem)
                                            mItem={}
                                        }else if(it.rules.cal_type=="end"){
                                            mItem.title=it.name
                                            mItem.value=item.json[it.page].list[it.current].data[1]?item.json[it.page].list[it.current].data[1].time:""
                                            rowItem.push(mItem)
                                            mItem={}
                                        }
                                        //console.log(mItem)
                                        break;
                                }
                            })
                            if(isCal){
                                //代表有统计记分
                                rowItem.push({
                                    title:"合计得分",
                                    value:totalScore
                                })
                            }
                            //console.log(rowItem)
                            calList.push(rowItem)
                        }
                    })
                    this.calList=calList
                    console.log(`计算后合计:${calList.length}条`)
                    this.loading=false
                    if(beforeLength!=calList.length){
                        //数据有丢失的，可能是计算错误数据错误导致的
                        this.calMsg = `实际${beforeLength}条数据，计算出${calList.length}条数据，有${beforeLength-calList.length}条数据未导出！`
                        this.showMod=true
                    }
                }
             }).catch(err=>{
                //err
                console.log(err)
                this.loading=false 
                this.$message({
                    type: 'warning',
                    message: err.msg?err.msg:err
                }); 
                //console.log(err)
            })
        },
        indexMethod(index) {
            return index * 1+1;
        },
        async  getAuxiliary(queryString, cb){
            let optionList=[]
            ,params = {
                    method:"getAuxiliary",
                    keys:this.form.keys
                }
                let {data} =await this.$ajax.post(this.API.api,params)
                //console.log(data)
                if(data){
                    data.forEach((item,index)=>{
                        optionList.push({
                            value:item.name,
                            id:index,
                            content:JSON.parse(item.content)
                        })
                    })
                    this.optionList = optionList
                    //console.log(this.optionList)
                    //搜索
                    this.querySearchStaff(queryString, cb)
                }
        },
        handleSelect(e){
            if(e.value)
            this.form.keys = e.value
            this.name = e.value
            this.ASList = this.optionList[e.id].content
        },
         querySearchStaff(queryString, cb) {
            var optionList = this.optionList;
            var results = queryString ? optionList.filter(this.createFilterStaff(queryString)) : optionList;
            // 调用 callback 返回建议列表的数据
            //console.log(results,optionList)
            cb(results);
        },
        createFilterStaff(queryString) {
            return (optionList) => {
            return (optionList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
        height: 78vh;
        overflow: auto;
    }
</style>