/**
 * 引导元素信息配置
 * 上一步下一步按钮文字都可以配置
 */
 export default [
    {
      element: '#addBtn',  //设置需要引导的元素id
      popover: {
        title: '添加工单',
        description: '你可以点击此次添加工单',
        position: 'top',
        className: 'first-step-popover-class', //自定义样式
        nextBtnText: '(1/3)已知晓，下一步' //这里也可以自定义下一步按钮上的文字哦
      }
    },
    {
      element: '#searchBtn',
      popover: {
        title: '搜索',
        description: '输入搜索条件可以搜索工单',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(2/3)下一步'
      }
    },{
      element: '#mms_id',
      popover: {
        title: '辅助统计',
        description: '可通过辅助统计快速获取需要的数据',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(3/3)我知道了'
      }
    }
  ];
  
  