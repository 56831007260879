<template>
    <div class="add-task-box">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="客户名称" required>
                <el-autocomplete
                class="inline-input"
                v-model="form.client_name"
                :fetch-suggestions="getClientList"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="执行员工" required>
                <el-autocomplete
                class="inline-input"
                v-model="form.staff_name"
                :fetch-suggestions="getStaff"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="服务项目" required>
                <el-select v-model="form.project" placeholder="请选择">
                    <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="任务标签">
                <el-select v-model="form.tag" placeholder="请选择">
                    <el-option
                    v-for="item in tagList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间范围">
                <el-date-picker
                v-model="form.start_end"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
             <el-form-item label="备注信息">
                <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <!--  -->
            <el-form-item>
                <el-button :disabled="loading" type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {formatTime} from '@/utils/util.js'
export default {
    data(){
        return {
            loading:false,
            form:{
                start_end:[new Date(),new Date()]
            },
            projectList:[],
            tagList:[
            ],
            staffList:[
                {
                    value:'陈胜',
                    id:0
                }
            ],
            clientList:[]
        }
    },
    created(){
        //获取服务项目
        this.getProject()
        //获取标签
        this.getTag()
    },
    methods:{
        //获取标签
        getTag(){
            let params = {
                method:"getTag",
            },that=this
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                res.data.forEach(function(item){
                    that.tagList.push({
                        label:item.name,
                        value:item.name
                    })
                })
                this.loading = false
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //获取员工姓名
        async  getStaff(queryString, cb){
            //console.log(this.form.staff_name)
            //console.log(queryString)
            let staffList=[]
            ,params = {
                    method:"getStaffList",
                    page:0,
                    keys:this.form.staff_name,
                    pageSize:10
                }
                let {data} =await this.$ajax.post(this.API.api,params)
                // console.log(data)
                // console.log(data.list)
                if(data.list){
                    data.list.forEach((item)=>{
                        staffList.push({
                            value:item.username,
                            id:item.userid
                        })
                    })
                    this.staffList = staffList
                    //搜索
                    this.querySearchStaff(queryString, cb)
                }
        },
        //获取服务项目
        getProject(){
            let params = {
                method:"getProject",
            },that=this
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.loading = false
                if(res.data){
                    res.data.forEach(function(item){
                        that.projectList.push({
                            label:item.name,
                            value:item.name
                        })
                    })
                }
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //提交
        onSubmit(){
            //console.log(this.form)
            if(!this.form.client_name||!this.form.staff_name){
                this.$message({
                    message: this.CONST.FAIL_LOGIN_PARAMS,
                    type: 'warning'
                });
                return
            }
            if(!this.form.project){
                this.$message({
                    message: this.CONST.REQUIRED_PROJECT,
                    type: 'warning'
                });
                return
            }
            console.log(this.form.start_end)
            if(!this.form.start_end){
                this.$message({
                    message: this.CONST.INPUT_REQUIRED_TIME,
                    type: 'warning'
                });
                return
            }
            this.form.start = formatTime(this.form.start_end[0])
            this.form.end = formatTime(this.form.start_end[1])
            this.form.method = 'addTask'
            this.loading = true
            // let params = {
            //     method:'addTask',
            //     data:this.form
            // }
            this.$ajax.post(this.API.api,this.form).then(res=>{
                console.log(res)
                this.loading = false
                this.$message({
                    message: this.CONST.SUC_ADD,
                    type: 'success'
                });
                this.$emit('addSuc')
                //清空数据
                this.form.client_name = ''
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg||this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },//搜索客户
        getClientList(queryString, cb){
            let that=this
                that.loading = true
                let params = {
                    method:'getClientList',
                    page:1,
                    pageSize:5,
                    client:this.form.client_name
                }
                //console.log(this.form)
                that.$ajax.post(that.API.api,params).then(res=>{
                    //todo
                    that.loading = false
                    let list = [] 
                    if(res.data.list){
                        res.data.list.forEach(function(item){
                            list.push({
                                value:item.shortname,
                                cid:item.cid
                            })
                        })
                    }
                    that.clientList = list 
                    that.querySearch(queryString, cb)
                }).catch(err=>{
                    //err
                    console.log(err)
                    that.loading = false
                    that.$message({
                        message: err.msg?err.msg:that.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        handleSelect(e){
            console.log(e)
        },
        querySearch(queryString, cb) {
            var results = queryString ? this.clientList.filter(this.createFilter(queryString)) : this.clientList;
            // 调用 callback 返回建议列表的数据
            //console.log(results)
            cb(results);
           
        },
        createFilter(queryString) {
            return (clientList) => {
            //console.log(clientList,queryString)
            //console.log(clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            return (clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        },
        querySearchStaff(queryString, cb) {
            var staffList = this.staffList;
            //console.log(staffList)
            var results = queryString ? staffList.filter(this.createFilterStaff(queryString)) : staffList;
            // 调用 callback 返回建议列表的数据
            console.log(results,staffList)
            cb(results);
        },
        createFilterStaff(queryString) {
            return (staffList) => {
            return (staffList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        }
    }
}
</script>
<style lang="less">
.add-task-box{
    display: flex;
}
</style>