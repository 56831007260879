<template>
    <div class="img-scroll demo-image__lazy">
         <el-image
            v-for="item in imgs"
            :key="item"
            style="width: 120px; height: 80px"
            :preview-src-list="imgs"
            lazy
            fit="cover"
            :src="item">
        </el-image>
    </div>
</template>
<script>
export default {
    name:"imageGroup",
    props:{
        images:{
            type:[String,Array]
        }
    },
    data(){
        return{
            imgs:[]
        }
    },
    watch:{
        images:{
            handler(val){
                try {
                    if(val instanceof Array){
                        this.imgs=val
                    }else{
                        this.imgs = JSON.parse(val)
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.img-scroll{
 width:120px;
 height: 80px;
 white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling:touch;
}
</style>