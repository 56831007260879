<template>
    <div class="task-box" v-loading="loading">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>工单中心</el-breadcrumb-item>
            <el-breadcrumb-item>工单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addTaskRule">
                     <el-button round @click="add" id="addBtn" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item v-if="addTaskRule">
                    <el-button v-if="addTaskRule" round
                        @click="exportD" type="warning" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item>
                    <el-input size="normal" placeholder="请输入客户名" v-model="client">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                    class="inline-input"
                    v-model="form.staff_name"
                    :fetch-suggestions="getStaff"
                    placeholder="请输入员工"
                    @select="handleSelect"
                    ></el-autocomplete>
                     <!-- <el-input size="normal" placeholder="请输入员工" v-model="staff">
                    </el-input> -->
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" id="searchBtn" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                    <el-button class="margin" @click="showAuxiliary=true" type="warning" id="mms_id" icon="el-icon-s-data" size="small">辅助统计</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-task -->
        <div class="task-table">
            <el-table
            border
            v-loading="loading"
            @row-dblclick="tbClick"
            :data="taskList"
            style="width: 100%">
                <el-table-column
                    label="日期">
                    <template slot-scope="scope">
                        {{scope.row.start_time|dateFormat(scope.row.start_time)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="tid"
                    label="工单id">
                </el-table-column>
                <el-table-column
                    prop="client_name"
                    label="客户">
                </el-table-column>
                <el-table-column
                    prop="staff_name"
                    label="员工">
                </el-table-column>
                <el-table-column
                    prop="sign"
                    label="任务标签">
                </el-table-column>
                <el-table-column
                    prop="project"
                    label="服务项目">
                </el-table-column>
                <el-table-column
                    prop="start_time"
                    label="开始-上传 时间">
                    <template slot-scope="scope">
                    {{scope.row.start_time|timeRangeFormat(scope.row.end_time)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                    label="状态">
                    <template slot-scope="scope">
                        <Status :status="scope.row.status*1" :title="scope.row.status|statusFormat"/>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="addTaskRule"
                fixed="right"
                label="操作">
                    <template slot-scope="scope">
                        <span class="inreport" @click="seeItem(scope.row,true)">页内查看</span>
                        <i class="edit el-icon-view" @click="seeItem(scope.row)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.tid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- end -->
        <!-- page分页 -->
        <div class="pageNav" v-if="taskList.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- addTask -->
        <el-drawer
          title="添加任务"
          :visible.sync="drawer"
          size="50%"
          :direction="direction">
            <AddTask @addSuc="closeDrawer"/>
        </el-drawer>
        <!-- 辅助统计 -->
        <el-dialog title="辅助统计" width="90%"
          :visible.sync="showAuxiliary">
        <AuxiliaryStatistics v-if="showAuxiliary" :timeArr="timeArr"/>
        </el-dialog>
        <!-- 弹出报告 -->
            <el-dialog v-el-drag-dialog  append-to-body :title="dialogTitle"
            :mask="false" :modal="false" :visible.sync="showReport" width="40%">
                <ReportShow :id="tid" :user_token="token" :isGuide="true"/>
            </el-dialog>
    </div>
</template>
<script>
import driverSteps from '@/views/Task/Scheduling_steps.js'
import {exportExcel} from '@/utils/excel.js'
import {formatTime,checkRules} from '@/utils/util.js'
import Status from '@/components/common/Status'
import AddTask from '@/components/AddTask'
import AuxiliaryStatistics from '@/components/test/AuxiliaryStatistics'
import ReportShow from '@/views/Report/show'
export default {
    components:{
        Status,
        AddTask,
        ReportShow,
        AuxiliaryStatistics
    },
    data(){
        return{
            hint:"",
            dialogTitle:"",
            showReport:false,
            tid:"",
            token:"",
            showAuxiliary:false,//显示辅助统计
            staffList:[],
            form:{},
            dType:0,//下载类别
            dialogTableVisible:false,
            addTaskRule:checkRules(10),//添加编辑任务权限
            loading:false,
            client:'',//搜索客户，
            staff:'',
            timeArr:'',
            taskList:[],
            page:1,
            pageSize:10,
            totalPage:0,
            drawer: false,
            direction: 'rtl',
            pageArr:[5,10,15,20] //每页显示
        }
    },
    created(){
        //初始化数据
        // var day1 = new Date();
        // day1.setTime(day1.getTime()-24*60*60*1000);
        let now = formatTime(new Date) 
        ,str = now.split(" ")[0]
        let start = str.split("-"),
        start_time = `${start[0]}/${start[1]}/01 00:00:00`
        //console.log('start',start_time,new Date(start_time).getTime(),new Date(new Date().getTime()+24*60*60*1000))
        this.timeArr=[new Date(start_time),new Date(new Date().getTime()+24*60*60*1000)]
        this.search()
    },
    mounted(){
        this.guide()
    }
    ,methods:{
        guide(){
            //引导页
            let hasGuide = window.localStorage.getItem("hasGuide_scheduling")
            // console.log(hasGuide)
            // this.$Driver.defineSteps(driverSteps)
            //     this.$Driver.start()
            if(!hasGuide){
                this.$Driver.defineSteps(driverSteps)
                this.$Driver.start()
                window.localStorage.setItem("hasGuide_scheduling",'true')
            }
            
        },
        //双击查看报告
        tbClick(row){
            this.seeItem(row,true)
        },
        //获取员工姓名
        async  getStaff(queryString, cb){
            //console.log(this.form.staff_name)
            //console.log(queryString)
            let staffList=[]
            ,params = {
                    method:"getStaffList",
                    page:0,
                    keys:this.form.staff_name,
                    pageSize:10
                }
                let {data} =await this.$ajax.post(this.API.api,params)
                // console.log(data)
                // console.log(data.list)
                if(data.list){
                    data.list.forEach((item)=>{
                        staffList.push({
                            value:item.username,
                            id:item.userid
                        })
                    })
                    this.staffList = staffList
                    //搜索
                    this.querySearchStaff(queryString, cb)
                }
        },
        handleSelect(e){
            if(e.value)
            this.staff = e.value
        },
         querySearchStaff(queryString, cb) {
            var staffList = this.staffList;
            //console.log(staffList)
            var results = queryString ? staffList.filter(this.createFilterStaff(queryString)) : staffList;
            // 调用 callback 返回建议列表的数据
            //console.log(results,staffList)
            cb(results);
        },
        createFilterStaff(queryString) {
            return (staffList) => {
            return (staffList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        exportD(){
            let params = {
                method:'getTask',
                pageSize:20000,
                page:1,
                staff:this.staff,
                client:this.client,
                dType:this.dType,//下载类型，是否包含其他时间数据 
                start:formatTime(this.timeArr[0]),
                end:formatTime(this.timeArr[1])
            },taskList=[]
            this.loading=true
            let that = this
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.loading=false
                if(res.data)
                taskList=res.data.list
                console.log(taskList)
                 let columnTitle=['日期','客户名称','服务人员','服务时间（分钟）','服务项目','类型','开始时间','报告提交时间','状态']
            let columnData = []
            console.log(res.data.list)
            res.data.list.forEach(it=>{
                    let date = it.start_time.split(" ")[0],status = that.getStatus(it.status)
                    columnData.push([date,it.client_name,it.staff_name,it.use_time,it.project,it.sign,it.start_time,it.end_time,status])
            })
            exportExcel(columnTitle, columnData, formatTime(this.timeArr[0])+'-'+formatTime(this.timeArr[1])+"服务工单导出数据");
            }).catch(err=>{
                //err
                this.loading=false 
                this.$message({
                    type: 'warning',
                    message: err.msg?err.msg:err
                }); 
                //console.log(err)
            })
        },
        getStatus(status){
            let str = '未完成'
            switch(status*1){
                case 0:
                    str = '进行中'
                    break;
                case 1:
                    str = '未接收'
                    break;
                case 2:
                    str = '进行中'
                    break;
                case 3:
                    str = '已完成'
                    break;
                case 4:
                    str = '以放弃'
                    break;
                case 5:
                    str = '已完成'
                    break;
            }
            return str
        },
        choisePageSize(pageSize){
            this.pageSize = pageSize 
            this.search()
        },
        //点击页码
        currentChange(e){
            this.page = e 
            this.getTask()
        },
        //查看
        seeItem(row,inPage){
            let tid = row.tid
            let user = JSON.parse(sessionStorage.getItem('user'))
            this.dialogTitle = tid+'_'+row.client_name
            if(row.project=="餐饮虫害防制"||row.project=="其他类型"||row.project=="结构排查"){
                let url = "https://yhipm.cn/system/admin/index/navReport_"+tid;
                window.open(url,'_blank')
                return
            }
            if(inPage){
                //页面内查看
                this.tid = tid 
                this.token = user.token
                this.showReport = true
            }else{
                //console.log(row)
                let url = this.$router.resolve({path:'/Report/show/'+tid,query:{token:user.token,isHint:true}})
                //console.log(url)
                window.open(url.href, '_blank');
            }
            
        },
        //删除
        handleDelete(tid){
            //console.log(tid)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:'delTask',
                        tid
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        console.log(res)
                        //刷新
                        this.search()
                        this.$message({
                            message: res.msg||this.CONST.DEL_SUC,
                            type: 'success'
                        });
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false 
                        this.$message({
                            message: err.msg||this.CONST.DEL_FAILD,
                            type: 'warning'
                        });
                    })

            }).catch(err=>{
                console.log(err)
            })
        },
        //搜索任务
        search(){
            if(this.timeArr.length<=0){
                this.$message({
                    type: 'warning',
                    message: this.CONST.INPUT_REQUIRED_TIME
                }); 
                return
            }
            this.page = 1
            this.taskList= []
            this.getTask()
        },
        add(){
            this.drawer = true
        },
        closeDrawer(){
            this.drawer = false
            //刷新任务列表
            this.page = 1
            this.getTask()
        },
        //获取任务列表
        getTask(){
            //console.log(this.timeArr)
            let params = {
                method:'getTask',
                pageSize:this.pageSize,
                page:this.page,
                staff:this.staff,
                client:this.client,
                start:formatTime(this.timeArr[0]),
                end:formatTime(this.timeArr[1])
            }

            console.log(params)
            this.loading=true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                this.loading=false
                if(res.data)
                this.taskList=res.data.list
                this.totalPage=res.data.totalPage
            }).catch(err=>{
                //err
                this.loading=false 
                this.$message({
                    type: 'warning',
                    message: err.msg?err.msg:err
                }); 
                //console.log(err)
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
        height: 78vh;
        overflow: auto;
    }
.inreport{
    color:rgb(94, 93, 92);
    cursor:pointer;
    font-size: 0.8em;
    text-decoration:underline;
}
.inreport:hover{
    color:rgb(250, 102, 3);
}
.task-box{
    padding:5px 0;

    .header-bar{
        margin:10px 0;
        display: flex;

        .input{
            width:230px;
            margin-left: 10px;
        }
        .time-box{
            width:350px;
            margin-left: 10px;
        }
        .margin{
            margin-left: 10px;
        }
    }

    .edit.el-icon-view,.edit.el-icon-delete-solid{
        font-size:0.8em;
        margin-left: 10px;
        cursor:pointer;
    }
    .el-icon-view{
        color: #39F;
    }
    .el-icon-delete-solid{
        color:rgb(107, 101, 101);
    }
    // 分页数
    .pageNav{
        padding: 10px 0;
        display: flex;
        .el-dropdown{
            line-height: 28px;
        }
    }
}
</style>